import React,{lazy,Suspense} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import App from './App';
import { BrowserRouter as Router, } from 'react-router-dom';
const App = lazy(() => import("./App"));

ReactDOM.render(
  <React.StrictMode>
  <Router>
  <Suspense fallback={<div></div>}>
    <App />
    </Suspense>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);
